import React from "react"
import TabNavigation from "../../components/TabNavigation"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import ModalLink from "../../components/ModalLink"
import ColumnGrid from "../../components/ColumnGrid"
import css from "./stylesheets/basicInformation.module.scss"

import Seo from "../../components/Seo"
import IconInfo from "../../images/icon_info.svg"
import CenterContentLayout from "../../components/CenterContentLayout"

function PageBasicInformation(props) {
  return (
    <div>
      <Seo title={`ABOUT JAPAN | BASIC INFORMATION`} />

      <LayoutBasic lang={`ja`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              日本はどんな国？ 日本で介護の仕事をするには？
              <br />
              日本の基本的な情報や、介護の仕事をするための4つの在留資格などを紹介します。
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              {
                title: `ABOUT JAPAN`,
                link: `/ja/basic-information/`,
                current: true,
              },
              { title: `ABOUT KAIGO`, link: `/ja/basic-information/kaigo/` },
            ]}
          />
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT JAPAN</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_01.svg`}
                alt="面積"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>面積:</dt>
                <dd>
                  378,000km<sup>2</sup>
                </dd>
              </dl>
              南北に長い、東アジア最大の島国。国土面積では世界で約60番目、アジアではベトナムやマレーシア、ヨーロッパではドイツなどと同じくらいの広さです。北から北海道、本州、四国、九州、沖縄の5つの島があり、そのほかにも6,800以上の島があります。
            </div>
          </Info2column>
          <Info2column
            id={`section-2`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_02.svg`}
                alt="人口"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>人口:</dt>
                <dd>
                  約1億2,602万人<sup>※1</sup>
                </dd>
              </dl>
              <dl>
                <dt>平均年齢:</dt>
                <dd>46.4歳</dd>
              </dl>
              日本の総人口は、2008年の1億2,808万人をピークに減少しています。2019年、生産年齢人口（15-64歳）は全体の約60%、高齢人口（65歳以上）は約28%で、世界一の高齢化率です。
              <sup>※2</sup>
            </div>
          </Info2column>
          <Info2column
            id={`section-3`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_03.svg`}
                alt="言語"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>言語:</dt>
                <dd>日本語</dd>
              </dl>
              日本語では、書き言葉に「漢字」「ひらがな」「カタカナ」があり、表記によっては「ローマ字」も使います。また話し言葉は、地域によって方言があります。
            </div>
          </Info2column>
          <Info2column
            id={`section-4`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_04.svg`}
                alt="通貨"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>通貨:</dt>
                <dd>円（￥）</dd>
              </dl>
              硬貨6種類と紙幣4種類が使われています。為替レートは1USドル＝約110円（2020年2月）。都心部の駅やお店では、電子マネーが使えるところも増えてきています。
            </div>
          </Info2column>
          <Info2column
            id={`section-5`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_05.svg`}
                alt="宗教"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>宗教:</dt>
                <dd>
                  神道、仏教、
                  <br />
                  キリスト教、その他
                </dd>
              </dl>
              新年に神社や寺院にお参りする「初詣」や先祖を供養する「お盆」、クリスマスなど、神道、仏教、キリスト教などのさまざまな宗教文化が混ざり合っています。普段、宗教を意識していない人も多いです。
            </div>
          </Info2column>
          <Info2column
            id={`section-6`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_06.svg`}
                alt="政治"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>政治:</dt>
                <dd>民主主義</dd>
              </dl>
              日本国憲法の三大原理「国民主権」「基本的人権の尊重」「平和主義」にもとづいて行われています。国民が選挙で選んだ代表者が政治を行う、民主主義の国です。
            </div>
          </Info2column>
          <Info2column
            id={`section-7`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_07.svg`}
                alt="気候"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <dl>
                <dt>気候:</dt>
                <dd>亜寒帯〜亜熱帯</dd>
              </dl>
              日本は南北に長いため、北部の亜寒帯気候から南部は亜熱帯気候までさまざまです。ただ、多くが温帯に属し、春・夏・秋・冬の4つの季節の違いがはっきりと現れます。山脈が多いため、冬は日本海側では曇りや雪、雨の日が多く、太平洋側では晴れの日が多くなるなど、地域によって大きく変わります。
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <hr className={`only-pc`} />
            <h4 style={{ fontSize: `16px` }}>
              各都市<sup>※3</sup>:
            </h4>
          </BasicText>

          <div className={css.BasicInfoCityGrid}>
            <ColumnGrid column={4} columnSp={2}>
              <ModalLink id={`modal-1`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_01.svg`}
                    alt="札幌市（北海道）"
                    width={280}
                    height={190}
                  />
                  <span>
                    札幌市（北海道）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>札幌市（北海道）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_01.svg`}
                      alt="札幌市（北海道）"
                    />
                  </p>
                  <p>
                    8月の平均最高気温は26.4℃。梅雨や台風の影響が少なく過ごしやすい気候です。1月の平均最低気温は-7℃。日本国内では寒い地域ですが、屋内は暖房が整った環境が多く、快適に過ごせます。また、雪は多いときで1m以上積もることもあります。札幌市内にもウィンタースポーツが楽しめるスキー場があります。また農業・漁業ともに盛んで、食べ物は、寿司や海鮮料理、ジンギスカン、ラーメン、スープカレーなどが有名です。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-2`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_02.svg`}
                    alt="仙台市（宮城県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    仙台市（宮城県）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>仙台市（宮城県）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_02.svg`}
                      alt="仙台市（宮城県）"
                    />
                  </p>
                  <p>
                    東北地方で一番人口の多い仙台。東京には新幹線で約1時間半の距離です。8月の平均最高気温は27.9℃、1月の平均最低気温は-1.7℃。東北は、雪が多く降るイメージがありますが、仙台は比較的雪が少なく、年間を通して過ごしやすいのが特徴です。また、海も山も近く、食材に恵まれています。秋から冬にかけては、牡蠣やサンマなどが有名です。笹かまぼこなどや牛タンも人気です。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-3`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_03.svg`}
                    alt="東京都23区"
                    width={280}
                    height={190}
                  />
                  <span>
                    東京都23区
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>東京都23区</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_03.svg`}
                      alt="東京都23区"
                    />
                  </p>
                  <p>
                    東京は日本最大の都市。千代田区の8月の平均最高気温は30.8℃、1月の平均最低気温は0.9℃です。太平洋側に位置する東京は、夏は比較的蒸し暑く、台風の影響を受けることがあり、日本のなかでは降水量も多い地域です。食べ物は、江戸前寿司、もんじゃ焼きが有名で、世界各国のさまざまな料理が食べられます。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-4`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_04.svg`}
                    alt="名古屋市（愛知県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    名古屋市（愛知県）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>名古屋市（愛知県）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_04.svg`}
                      alt="名古屋市（愛知県）"
                    />
                  </p>
                  <p>
                    本州の中央に位置する名古屋。そのため、日本の平均的な気候で、穏やかです。8月の平均最高気温は32.8℃。太平洋側のため、台風の影響を受けることもあり、6〜7月や9月に雨が多いです。1月の平均最低気温は0.8℃で、雨は少ないです。愛知は味噌の文化があり、味噌カツ、味噌煮込みうどんなどがあります。きしめん、小倉トーストなども有名です。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-5`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_05.svg`}
                    alt="大阪市（大阪府）"
                    width={280}
                    height={190}
                  />
                  <span>
                    大阪市（大阪府）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>大阪市（大阪府）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_05.svg`}
                      alt="大阪市（大阪府）"
                    />
                  </p>
                  <p>
                    大阪は、日本で2番目の大都市。世界遺産の多い京都や、港町の神戸なども近い場所にあります。気候は、瀬戸内海に面するため1年を通して温暖です。8月の平均最高気温は33.4℃と、日本のなかでも暑い地域になります。1月の平均最低気温は2.8℃です。食べ物は、たこ焼きやお好み焼き、串カツなどが有名です。また、大阪は「笑いの聖地」とも呼ばれ、ユーモアの文化もあります。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-6`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_06.svg`}
                    alt="広島市（広島県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    広島市（広島県）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>広島市（広島県）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_06.svg`}
                      alt="広島市（広島県）"
                    />
                  </p>
                  <p>
                    広島のある瀬戸内海地域は、山地に囲まれているため、1年を通して晴れが多く、雨が少ない、とても過ごしやすい地域です。8月の平均最高気温は32.5℃、1月の平均最低気温は1.7℃。また、広島は牡蠣の生産量が日本一です。お好み焼きが有名で、市内にはたくさんのお店が並びます。市内には、「平和記念公園」や世界遺産である「原爆ドーム」などがあり、世界へ平和を訴えるまちでもあります。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-7`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_07.svg`}
                    alt="福岡市（福岡県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    福岡市（福岡県）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>福岡市（福岡県）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_07.svg`}
                      alt="福岡市（福岡県）"
                    />
                  </p>
                  <p>
                    九州地方最大の都市である福岡。「アジアフォーカス・福岡国際映画祭」「福岡アジア美術館」など、日本で一番ほかのアジアの国に近い都市のひとつとして、アジア文化の交流をすすめています。8月の平均最高気温は32.1℃、1月の平均最低気温は3.5℃。夏から秋にかけては、台風の影響を受けやすいですが、1年を通して温暖で過ごしやすいです。もつ鍋、博多ラーメン、寿司、餃子、明太子、うどんなどの食べ物も豊富です。
                  </p>
                </div>
              </ModalLink>
              <ModalLink id={`modal-8`}>
                <div className={css.BasicInfoGridModalLinksItem}>
                  <img
                    src={`/assets/images/basic_info/basic_info_city_08.svg`}
                    alt="那覇市（沖縄県）"
                    width={280}
                    height={190}
                  />
                  <span>
                    那覇市（沖縄県）
                    <img src={IconInfo} alt="" />
                  </span>
                </div>
                <div>
                  <h3>那覇市（沖縄県）</h3>
                  <p>
                    <img
                      src={`/assets/images/basic_info/basic_info_city_08.svg`}
                      alt="那覇市（沖縄県）"
                    />
                  </p>
                  <p>
                    那覇のある美しい海に囲まれた沖縄本島は、1年を通して気温が高く、雨の多い地域です。8月の平均最高気温は31.5℃、1月の平均最低気温は14.6℃。冬もほかの地域に比べると気温が下がらず、雪は降りません。ビーチやサンゴ礁、琉球王国時代の遺跡など観光地としても人気の高い場所です。食べ物は、沖縄そばや沖縄の家庭料理のほか、タコライスやステーキなどアメリカの料理から生まれたものも多く見られます。
                  </p>
                </div>
              </ModalLink>
            </ColumnGrid>
          </div>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-9`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_people.svg`}
                alt="在留外国人数"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>外国人数</h3>
              <dl>
                <dt>在留外国人数:</dt>
                <dd>
                  2,829,416人
                  <br />
                  <small>（男性：1,387,401人／女性：1,442,015人）</small>
                  <sup>※4</sup>
                </dd>
              </dl>
              <dl>
                <dt>外国人労働者数:</dt>
                <dd>
                  1,460,463人
                  <br />
                  <small>（医療・福祉：26.086人）</small>
                  <sup>※5</sup>
                </dd>
              </dl>
              在留外国人、外国人労働者ともに年々増えています。国籍では中国、ベトナム、フィリピンが半数以上です。また産業別では、製造業が一番多く、医療・福祉分野で働く外国人は全体の1.8%と、まだまだ少ないほうです。
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <ul className={`notes`}>
              <li>
                <i>※1</i>
                <span>総務省統計局「統計データ」（2020年1月1日）より。</span>
              </li>
              <li>
                <i>※2</i>
                <span>
                  内閣府「高齢社会白書」（2019年）、独立行政法人労働政策研究・研修機構「データブック国際労働比較2018」より。
                </span>
              </li>
              <li>
                <i>※3</i>
                <span>
                  各地の気温については1981〜2010年の平均値、気象庁「過去の気象データ検索」より。
                </span>
              </li>
              <li>
                <i>※4</i>
                <span>
                  法務省出入国管理庁「報道資料｜令和元年6月末現在における在留外国人数について」（2019年）より。
                </span>
              </li>
              <li>
                <i>※5</i>
                <span>
                  厚生労働省「『外国人雇用状況』の届出状況まとめ」（2018年）より。
                </span>
              </li>
            </ul>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default PageBasicInformation
